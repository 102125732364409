import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CodeExample from "../components/home/CodeExample"
import { LandingPageFeatures } from "../components/landingPage/LandingPageFeatures"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "35px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
  logo: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginTop: "35px",
      marginBottom: "15px",
    },
  },
  codeexample: {
    [theme.breakpoints.up("md")]: { marginTop: "20px" },
  },
}))

const VueLandingPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Vue i18n Localization"
          subtitle="Translate your vue i18n app"
          source="Signup HeroVue"
        />
      </HeroSection>

      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Localize your Vue.js WebApp
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Use Simpleen to translate your Vue.js i18n JSON files from your
              software project. It can be customized with a glossary and either
              used directly or via the API.
              <br />
              <br />
              Simply copy/paste your JSON data from one language into our In-App
              Translate and receive instant results. Simpleen manages the
              correct handling of the format, the context and the glossary.
              <br />
              <br />
              Save time and money in your multilingual projects by using
              Simpleen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <GatsbyImage
              image={data.vue.childImageSharp.gatsbyImageData}
              alt="Vue.js i18n Logo"
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12} className={classes.codeexample}>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="ja"
              isTranslated={isFirstTranslated}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate Example"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <CompatibleLibraries currentPage="vue" />
      <LandingPageFeatures source="Signup ButtonVue" />

      <Seo
        general={{
          title: "Translate Vue.js i18n json",
          description:
            "Machine Translation of your software project with Vue.js i18n json or js files",
          path: location.pathname,
          image: data.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const vueLP3Query = graphql`
  query VueLP3Query {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: CONSTRAINED
        )
      }
    }
    vue: file(relativePath: { eq: "libraries/i18nVue.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 95
          layout: FIXED
          placeholder: BLURRED
        )
      }
    }
    snippetExample: markdownRemark(
      frontmatter: { slug: { eq: "/vueCodeLP3" } }
    ) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/vueCodeLP3Translated" } }
    ) {
      html
    }
  }
`

export default VueLandingPage
